import { Link, navigate } from 'gatsby';
import React, {useState} from 'react';
import {BASE_URL, CDN_URL } from '../../../config';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoginAction } from '../../redux/auth/authSlice';
const ForgotPassSection = () => {

    const dispatch = useDispatch();
    const [step, setStep] = useState(1)
    const [error, setError] = useState(null)

    const initialValues = {
        email: "",
        confirmationCode: "",
        newPassword: "",
        newPassword2: ""
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Campo obligatorio'),

        confirmationCode: Yup.string().when('step', {
            is: 2,
            then: (schema) => schema.required('Campo obligatorio'),
            otherwise: (schema) => schema
        }),

        newPassword: Yup.string().when('step', {
            is: 2,
            then: (schema) => schema.required('Campo obligatorio'),
            otherwise: (schema) => schema
        }),

        newPassword2: Yup.string().when('step', {
            is: 2,
            then: (schema) => schema
              .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas deben coincidir')
              .required('La confirmación de la contraseña es obligatoria'),
              otherwise: (schema) => schema
          }),

    });

    const handleSubmit = async (values) => {
        try {
            if(step == 1){
                await axios.post(BASE_URL + "/auth/forgot-password", values)  
                setStep(2);          
            }
            if(step == 2){
                await axios.post(BASE_URL + "/auth/confirm-password", values)
                const payload = {
                    email: values.email,
                    password: values.newPassword
                }
                const result = await axios.post(BASE_URL + "/auth", payload)
                
                const {jwtToken, userData} = result.data;
                const payloadRedux = {
                    jwtToken: jwtToken,
                    userData:  userData
                }
                dispatch(setLoginAction(payloadRedux));
                localStorage.clear();
                localStorage.setItem('authToken', JSON.stringify(result.data));
                navigate("/student-profile")
            }
            
        } catch (error) {
            
            setError(error.response.data)
        }
    }

    

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, isSubmitting }) => (
                <div className="signin-page-area pt-120 pb-120">
                    <div className="signin-page-area-wrapper">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-10">
                                    <Form>
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <div className='d-flex justify-content-center mt-3'>
                                                    <Link to="/"><img src={`${CDN_URL}/public/logo.png`} className='text-center' style={{ width: '200px' }} /></Link>
                                                </div>
                                                <div className="signup-form-wrapper">
                                                    {step == 1 &&
                                                    <>
                                                        <p className='mb-25'>Porfavor ingrese su correo electrónico, recibirá un código de confirmación.</p>
                                                        <div className="signup-wrapper">
                                                            <Field type="email" name="email" placeholder="Correo electrónico" />
                                                            <ErrorMessage component="div" className='text-danger' name="email"/>
                                                        </div>
                                                        {error && <div className='text-danger text-center m-2'>{error}</div>}
                                                        <div className="sing-buttom mb-20">
                                                        <button type="submit" disabled={isSubmitting} className="sing-btn">{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
                                                        </div>
                                                    </>
                                                    }
                                                    {step == 2 &&
                                                    <>
                                                        <div className="signup-wrapper">
                                                                <Field type="text" name="confirmationCode" placeholder="Código de confirmación" />
                                                                <ErrorMessage component="div" className='text-danger' name="confirmationCode"/>
                                                        </div>
                                                        <div className="signup-wrapper">
                                                                <Field type="password" name="newPassword" placeholder="Escriba su nueva contraseña" />
                                                                <ErrorMessage component="div" className='text-danger' name="newPassword"/>
                                                        </div>
                                                        <div className="signup-wrapper">
                                                                <Field type="password" name="newPassword2" placeholder="Confirme su nueva contraseña" />
                                                                <ErrorMessage component="div" className='text-danger' name="newPassword2"/>
                                                        </div>
                                                        {error && <div className='text-danger text-center m-2'>{error}</div>}
                                                        <div className="sing-buttom mb-20">
                                                        <button type="submit" disabled={isSubmitting} className="sing-btn">{isSubmitting ? 'Enviando...' : 'Enviar'}</button>
                                                        </div>
                                                    </>
                                                    }
                                                    
                                                    <div className="registered wrapper">
                                                        <div className="not-register">
                                                            <span>Regresar al</span><span><Link to="/login">Login</Link></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>

    );
};

export default ForgotPassSection;